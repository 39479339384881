import React, { useEffect } from 'react';
import AOS from 'aos';
import "aos/dist/aos.css";
import './index.css';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate
} from 'react-router-dom';
// All pages
import Home from './pages/Home';
import Contact from './pages/Contact';
import DemoProduct from './pages/DemoProduct';
import Dealer from './pages/Dealer';
import BrushCoat from './pages/BrushCoat';
import Blogs from './pages/Blogs';
import Applicators from './pages/Applicators';
import ImportanceOfWaterProofing from './pages/blogs/ImportanceOfWaterProofing';
import ChooseRightWaterproofing from './pages/blogs/ChooseRightWaterproofing';
import InnovativeWaterproofing from './pages/blogs/InnovativeWaterproofing';
import WaterproofingMarketTrends from './pages/blogs/WaterproofingMarketTrends';

import {useDocTitle} from './components/CustomHook';
import ScrollToTop from './components/ScrollToTop';

function App() {
  useEffect(() => {
    const aos_init = () => {
      AOS.init({
        once: true,
        duration: 1000,
        easing: 'ease-out-cubic',
      });
    }

    window.addEventListener('load', () => {
      aos_init();
    });
  }, []);

  useDocTitle("Ober India Chemicals");

  return (
    <>
      <Router>
        <ScrollToTop>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/home" element={<Home />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/get-demo" element={<DemoProduct />} /> 
            <Route path="/waterproofing-chemicals-dealer" element={<Dealer />} /> 
            <Route path="/brush-coat" element={<BrushCoat />} /> 
            <Route path="/waterproofing" element={<ChooseRightWaterproofing />} /> 
            <Route path="/blogs" element={<Blogs />} /> 
            <Route path="/waterproofing-applicators" element={<Applicators />} /> 
            <Route path="/blogs/importance-of-waterproofing" element={<ImportanceOfWaterProofing />} /> 
            <Route path="/importance-of-waterproofing" element={<ImportanceOfWaterProofing />} /> 
            <Route path="/blogs/choose-right-waterproofing" element={<ChooseRightWaterproofing />} /> 
            <Route path="/choose-right-waterproofing" element={<ChooseRightWaterproofing />} /> 
            <Route path="/innovative-waterproofing-technologies" element={<InnovativeWaterproofing />} /> 
            <Route path="/blogs/innovative-waterproofing-technologies" element={<InnovativeWaterproofing />} /> 
            <Route path="/blogs/waterproofing-market-trends" element={<WaterproofingMarketTrends />} /> 
            <Route path="*" element={<Navigate to ="/" />}/>

          </Routes>
        </ScrollToTop>
      </Router>
    </>
  );
}


export default App;
