import React, { useState } from "react";
import { Link } from "react-router-dom";
import { HashLink } from "react-router-hash-link";

const NavLinks = (props) => {
  const { handleClick } = props;
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };
  return (
    <>
      <HashLink
        className="px-4 font-extrabold text-gray-500 hover:text-blue-900"
        smooth
        to="/#products"
        onClick={handleClick}
      >
        Products
      </HashLink>
      <HashLink
        smooth
        to="/#services"
        className="px-4 font-extrabold text-gray-500 hover:text-blue-900"
        onClick={handleClick}
      >
        Services
      </HashLink>
      <HashLink
        className="px-4 font-extrabold text-gray-500 hover:text-blue-900"
        smooth
        to="/#about"
        onClick={handleClick}
      >
        About Us
      </HashLink>
      <HashLink
        className="px-4 font-extrabold text-gray-500 hover:text-blue-900"
        smooth
        to="/contact"
      >
        Contact Us
      </HashLink>
      <HashLink
        className="px-4 font-extrabold text-gray-500 hover:text-blue-900"
        smooth
        to="/blogs"
      >
        Blogs
      </HashLink>
      {/* Dropdown Menu for Register */}
      <div className="relative inline-block">
        <button
          onClick={toggleDropdown}
          className="text-white bg-blue-900 hover:bg-blue-800 inline-flex items-center justify-center w-auto px-6 py-3 shadow-xl rounded-xl"
        >
          Dealers/Applicators
        </button>

        {dropdownOpen && (
          <div className="absolute left-0 mt-2 w-48 bg-white border border-gray-200 rounded-lg shadow-lg z-10">
            <Link
              to="/waterproofing-applicators"
              className="block px-4 py-2 text-gray-700 hover:bg-blue-900 hover:text-white"
              onClick={toggleDropdown}
            >
              Applicators
            </Link>
            <Link
              to="/waterproofing-chemicals-dealer"
              className="block px-4 py-2 text-gray-700 hover:bg-blue-900 hover:text-white"
              onClick={toggleDropdown}
            >
              Dealers
            </Link>
          </div>
        )}
      </div>
    </>
  );
};

export default NavLinks;