import React from "react";
import { useDocTitle } from "../components/CustomHook";
import { Link } from "react-router-dom";

const BlogsCardList = ({ blogs }) => {
  useDocTitle("Ober India Chemicals - Blog");

  return (
    <>
      <div className="blog-card-list-container">
      {blogs.map((blog) => (
        <div key={blog.id} className="blog-card">
          <img
            src={blog.imageUrl}
            alt={blog.title}
            className="blog-card-image"
          />
          <div className="blog-card-content">
            <h3 className="blog-card-title">{blog.title}</h3>
            <p className="blog-card-excerpt">{blog.excerpt}</p>
            <Link
              to={`/blogs/${blog.id}`}
              className="blog-card-button"
            >
              Read More
            </Link>
          </div>
        </div>
      ))}
    </div>
    </>
  );
};

export default BlogsCardList;
