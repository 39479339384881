import { useEffect } from "react";
import { useLocation } from "react-router-dom";
// import { useHistory } from 'react-router-dom';

const ScrollToTop = (props) => {
    const location = useLocation();
    // const history = useHistory();
    useEffect(() => {
      if(location.pathname !== "/")
      window.scrollTo({top: 0, left: 0, behavior: 'smooth' });
    }, [location]);
  
    return( <>
        {props.children}
    </>)
  };

export default ScrollToTop;