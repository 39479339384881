import React from 'react';
import sandeep from '../images/clients/sandeep.png';
import honeywell from '../images/clients/honeywell.png';
import bhel from '../images/clients/bhel.png';
import waterboard from '../images/clients/waterboard.jpg';
import arkhomes from '../images/clients/arkhomes.jpeg';
import biological from '../images/clients/biological.jpeg';
import countoursteel from '../images/clients/contour-steel.png';
import raichandani from '../images/clients/raichandani.jpeg';
import sarvanilabs from '../images/clients/sarvanilabs.png';
import symphony from '../images/clients/symphony.png';

const clientImage = {
    height: '10rem',
    width: 'auto',
    mixBlendMode: 'colorBurn'
}

const Clients = () => {
    return (
        <div className="mt-8 bg-gray-100">
            <section>
                <div className="my-2 py-2">
                        <h2 className="my-2 text-center text-3xl text-blue-900 uppercase font-bold">Our Clients</h2>
                        <div className='flex justify-center'>
                            <div className='w-24 border-b-4 border-blue-900'></div>
                        </div>
                        <h2 className="mt-4 mx-12 text-center text-xl lg:text-2xl font-semibold text-blue-900">Some of our clients.</h2>
                    </div>

                <div className="p-6">
                    <div className="grid sm:grid-cols-5 lg:grid-cols-5">
                        <div style={clientImage} className= "overflow-hidden flex justify-center transition-all ease-in-out opacity-80 hover:opacity-100 w-1/6">
                            <img src={sandeep} alt="client" />                           
                        </div>

                        <div style={clientImage} className="overflow-hidden p-3 flex justify-center transition-all ease-in-out opacity-80 hover:opacity-100">
                            <img src={bhel} alt="client" />                            
                        </div> 

                        <div style={clientImage} className="overflow-hidden p-3 flex justify-center transition-all ease-in-out opacity-80 hover:opacity-100">
                            <img src={honeywell} alt="client" />                            
                        </div>
                        <div style={clientImage} className="overflow-hidden p-3 flex justify-center transition-all ease-in-out opacity-80 hover:opacity-100">
                            <img src={waterboard} alt="client" />                            
                        </div>
                        <div style={clientImage} className="overflow-hidden p-3 flex justify-center transition-all ease-in-out opacity-80 hover:opacity-100">
                            <img src={arkhomes} alt="client" />                            
                        </div>
                        <div style={clientImage} className="overflow-hidden p-3 flex justify-center transition-all ease-in-out opacity-80 hover:opacity-100">
                            <img src={biological} alt="client" />                            
                        </div>
                        <div style={clientImage} className="overflow-hidden p-3 flex justify-center transition-all ease-in-out opacity-80 hover:opacity-100">
                            <img src={countoursteel} alt="client" />                            
                        </div>
                        <div style={clientImage} className="overflow-hidden p-3 flex justify-center transition-all ease-in-out opacity-80 hover:opacity-100">
                            <img src={sarvanilabs} alt="client" />                            
                        </div>
                        <div style={clientImage} className="overflow-hidden p-3 flex justify-center transition-all ease-in-out opacity-80 hover:opacity-100">
                            <img src={symphony} alt="client" />                            
                        </div>
                        <div style={clientImage} className="overflow-hidden p-3 flex justify-center transition-all ease-in-out opacity-80 hover:opacity-100">
                            <img src={raichandani} alt="client" />                            
                        </div>
                                           
                    </div>
                </div>
            </section>
        </div>
    )
}

export default Clients;