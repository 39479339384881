import React, { useState } from 'react';
import NavBar from '../components/Navbar/NavBar';
import Footer from '../components/Footer';
import {useDocTitle} from '../components/CustomHook';
import emailjs from 'emailjs-com';
import Notiflix from 'notiflix';

const Contact = () => {
    useDocTitle("Ober India Chemicals - contact us");
    
    const [firstName, setFirstName] = useState('')
    const [lastName, setLastName] = useState('')
    const [email, setEmail] = useState('')
    const [phone, setPhone] = useState('')
    const [message, setMessage] = useState('')
    const [errors, setErrors] = useState([])

    const clearErrors = () => {
        setErrors([])
    }

    const clearInput = () => {
        setFirstName('')
        setLastName('')
        setEmail('')
        setPhone('')
        setMessage('')
        document.getElementById('submitBtn').innerHTML = 'Send Message';
    }

    const sendEmail = (e) => {
        e.preventDefault();
        document.getElementById('submitBtn').disabled = true;
        document.getElementById('submitBtn').innerHTML = 'Loading...';
        let fData = new FormData();
        fData.append('first_name', firstName)
        fData.append('last_name', lastName)
        fData.append('email', email)
        fData.append('phone_number', phone)
        fData.append('message', message);
        emailjs.sendForm(process.env.REACT_APP_EMAIL_SERVICE_KEY, process.env.REACT_APP_EMAIL_TEMPLATE_KEY, e.target, process.env.REACT_APP_EMAIL_PUBLIC_KEY)
          .then((result) => {
            document.getElementById("contact-form").reset();
              clearInput();
              Notiflix.Report.success(
                'Success',
                '"Thanks for sending a message, we\'ll be in touch soon."',
                'Okay',
                );
          }, (error) => {

            document.getElementById("contact-form").reset();
            console.log(error.text);
              Notiflix.Report.failure(
                'An error occured',
                'Please try sending the message again.',
                'Okay',
                );
          });
    }
    return (
      <>
        <div>
          <NavBar />
        </div>
        <div className="flex justify-center items-center mt-8 w-full bg-white py-12 lg:py-24 bg-gray-200">
          <div
            className="container mx-auto my-8 px-4 lg:px-20"
            data-aos="zoom-in"
          >
            <form id="contact-form" onSubmit={sendEmail}>
              <div className="w-full bg-white p-8 my-4 md:px-12 lg:w-9/12 lg:pl-20 lg:pr-40 mr-auto rounded-2xl shadow-2xl">
                <div className="flex">
                  <h1 className="font-bold text-center lg:text-left text-blue-900 uppercase text-4xl">
                    Send us a message
                  </h1>
                </div>
                <div className="grid grid-cols-1 gap-5 md:grid-cols-2 mt-5">
                  <div>
                    <input
                      name="first_name"
                      className="w-full bg-gray-100 text-gray-900 mt-2 p-3 rounded-lg focus:outline-none focus:shadow-outline"
                      type="text"
                      required
                      placeholder="First Name*"
                      value={firstName}
                      onChange={(e) => setFirstName(e.target.value)}
                      onKeyUp={clearErrors}
                    />
                    {errors && (
                      <p className="text-red-500 text-sm">
                        {errors.first_name}
                      </p>
                    )}
                  </div>

                  <div>
                    <input
                      name="last_name"
                      className="w-full bg-gray-100 text-gray-900 mt-2 p-3 rounded-lg focus:outline-none focus:shadow-outline"
                      type="text"
                      required
                      placeholder="Last Name*"
                      value={lastName}
                      onChange={(e) => setLastName(e.target.value)}
                      onKeyUp={clearErrors}
                    />
                    {errors && (
                      <p className="text-red-500 text-sm">{errors.last_name}</p>
                    )}
                  </div>

                  <div>
                    <input
                      name="email"
                      className="w-full bg-gray-100 text-gray-900 mt-2 p-3 rounded-lg focus:outline-none focus:shadow-outline"
                      type="email"
                      required
                      placeholder="Email*"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      onKeyUp={clearErrors}
                    />
                    {errors && (
                      <p className="text-red-500 text-sm">{errors.email}</p>
                    )}
                  </div>

                  <div>
                    <input
                      name="phone_number"
                      className="w-full bg-gray-100 text-gray-900 mt-2 p-3 rounded-lg focus:outline-none focus:shadow-outline"
                      type="number"
                      required
                      placeholder="Phone*"
                      value={phone}
                      onChange={(e) => setPhone(e.target.value)}
                      onKeyUp={clearErrors}
                    />
                    {errors && (
                      <p className="text-red-500 text-sm">
                        {errors.phone_number}
                      </p>
                    )}
                  </div>
                </div>
                <div className="my-4">
                  <textarea
                    name="message"
                    placeholder="Message*"
                    required
                    className="w-full h-32 bg-gray-100 text-gray-900 mt-2 p-3 rounded-lg focus:outline-none focus:shadow-outline"
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                    onKeyUp={clearErrors}
                  ></textarea>
                  {errors && (
                    <p className="text-red-500 text-sm">{errors.message}</p>
                  )}
                </div>
                <div className="my-2 w-1/2 lg:w-2/4">
                  <button
                    type="submit"
                    id="submitBtn"
                    className="uppercase text-sm font-bold tracking-wide bg-gray-500 hover:bg-blue-900 text-gray-100 p-3 rounded-lg w-full 
                                    focus:outline-none focus:shadow-outline"
                  >
                    Send Message
                  </button>
                </div>
              </div>
            </form>
            <div className="w-full  lg:-mt-96 lg:w-2/6 px-8 py-6 ml-auto bg-blue-900 rounded-2xl">
              <div className="flex flex-col text-white">
                <div className="flex my-4 w-2/3 lg:w-3/4">
                  <div className="flex flex-col">
                    <i className="fas fa-map-marker-alt pt-2 pr-2" />
                  </div>
                  <div className="flex flex-col">
                    <h2 className="text-2xl">Office Address</h2>
                    <p className="text-gray-400">
                      8-7-69/1A, Road No. 11, Shankar Enclave, Old Bowenpally,
                      Secunderabad, Telangana 500011
                    </p>
                  </div>
                </div>

                <div className="flex my-4 w-2/3 lg:w-1/2">
                  <div className="flex flex-col">
                    <i className="fas fa-phone-alt pt-2 pr-2" />
                  </div>

                  <div className="flex flex-col">
                    <h2 className="text-2xl">Call Us</h2>
                    <p className="text-gray-400">Tel: 91772 59479</p>

                    <div className="mt-5">
                      <h2 className="text-2xl">Send an E-mail</h2>
                      <p className="text-gray-400">
                        oberindiachemicals@gmail.com
                      </p>
                    </div>
                  </div>
                </div>

                <div className="flex my-4 w-2/3 lg:w-1/2">
                    {/* Instagram */}
                  <a
                    href="https://www.instagram.com/oberindia"
                    target="_blank"
                    rel="noreferrer"
                    className="m-auto rounded-full flex justify-center bg-white h-8 text-blue-900  w-8 inline-block mx-1 text-center pt-2 pl-1"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="currentColor"
                      className="fill-current font-black hover:animate-pulse m-inherit"
                    >
                      <path d="M8 0C5.829 0 5.556.01 4.703.048 3.85.088 3.269.222 2.76.42a3.9 3.9 0 0 0-1.417.923A3.9 3.9 0 0 0 .42 2.76C.222 3.268.087 3.85.048 4.7.01 5.555 0 5.827 0 8.001c0 2.172.01 2.444.048 3.297.04.852.174 1.433.372 1.942.205.526.478.972.923 1.417.444.445.89.719 1.416.923.51.198 1.09.333 1.942.372C5.555 15.99 5.827 16 8 16s2.444-.01 3.298-.048c.851-.04 1.434-.174 1.943-.372a3.9 3.9 0 0 0 1.416-.923c.445-.445.718-.891.923-1.417.197-.509.332-1.09.372-1.942C15.99 10.445 16 10.173 16 8s-.01-2.445-.048-3.299c-.04-.851-.175-1.433-.372-1.941a3.9 3.9 0 0 0-.923-1.417A3.9 3.9 0 0 0 13.24.42c-.51-.198-1.092-.333-1.943-.372C10.443.01 10.172 0 7.998 0zm-.717 1.442h.718c2.136 0 2.389.007 3.232.046.78.035 1.204.166 1.486.275.373.145.64.319.92.599s.453.546.598.92c.11.281.24.705.275 1.485.039.843.047 1.096.047 3.231s-.008 2.389-.047 3.232c-.035.78-.166 1.203-.275 1.485a2.5 2.5 0 0 1-.599.919c-.28.28-.546.453-.92.598-.28.11-.704.24-1.485.276-.843.038-1.096.047-3.232.047s-2.39-.009-3.233-.047c-.78-.036-1.203-.166-1.485-.276a2.5 2.5 0 0 1-.92-.598 2.5 2.5 0 0 1-.6-.92c-.109-.281-.24-.705-.275-1.485-.038-.843-.046-1.096-.046-3.233s.008-2.388.046-3.231c.036-.78.166-1.204.276-1.486.145-.373.319-.64.599-.92s.546-.453.92-.598c.282-.11.705-.24 1.485-.276.738-.034 1.024-.044 2.515-.045zm4.988 1.328a.96.96 0 1 0 0 1.92.96.96 0 0 0 0-1.92m-4.27 1.122a4.109 4.109 0 1 0 0 8.217 4.109 4.109 0 0 0 0-8.217m0 1.441a2.667 2.667 0 1 1 0 5.334 2.667 2.667 0 0 1 0-5.334" />
                    </svg>
                  </a>
                  {/* Twitter */}
                  <a
                    href="https://x.com/oberindia"
                    target="_blank"
                    rel="noreferrer"
                    className="rounded-full flex justify-center bg-white h-8 text-blue-900  w-8 inline-block mx-1 text-center"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="fill-current font-black hover:animate-pulse"
                    >
                      <path d="M24 11.5c-.6.3-1.2.4-1.9.5.7-.4 1.2-1 1.4-1.8-.6.4-1.3.6-2.1.8-.6-.6-1.5-1-2.4-1-1.7 0-3.2 1.5-3.2 3.3 0 .3 0 .5.1.7-2.7-.1-5.2-1.4-6.8-3.4-.3.5-.4 1-.4 1.7 0 1.1.6 2.1 1.5 2.7-.5 0-1-.2-1.5-.4 0 1.6 1.1 2.9 2.6 3.2-.3.1-.6.1-.9.1-.2 0-.4 0-.6-.1.4 1.3 1.6 2.3 3.1 2.3-1.1.9-2.5 1.4-4.1 1.4H8c1.5.9 3.2 1.5 5 1.5 6 0 9.3-5 9.3-9.3v-.4c.7-.5 1.3-1.1 1.7-1.8z" />
                    </svg>
                  </a>
                  {/* Facebook */}
                  <a
                    href="https://www.facebook.com/oberindiaconstructionchemicals/"
                    target="_blank"
                    rel="noreferrer"
                    className="rounded-full flex justify-center bg-white h-8 text-blue-900 w-8 inline-block mx-1 text-center pt-1"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="fill-current font-black hover:animate-pulse m-inherit "
                    >
                      <path d="M13.397 20.997v-8.196h2.765l.411-3.209h-3.176V7.548c0-.926.258-1.56 1.587-1.56h1.684V3.127A22.336 22.336 0 0 0 14.201 3c-2.444 0-4.122 1.492-4.122 4.231v2.355H7.332v3.209h2.753v8.202h3.312z"></path>
                    </svg>
                  </a>
                  {/* Linkedin */}
                  <a
                    href="https://www.linkedin.com/company/ober-india-chemicals/"
                    target="_blank"
                    rel="noreferrer"
                    className="rounded-full flex justify-center bg-white h-8 text-blue-900  w-8 inline-block mx-1 text-center pt-1"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="fill-current font-black hover:animate-pulse m-inherit "
                    >
                      <circle cx="4.983" cy="5.009" r="2.188"></circle>
                      <path d="M9.237 8.855v12.139h3.769v-6.003c0-1.584.298-3.118 2.262-3.118 1.937 0 1.961 1.811 1.961 3.218v5.904H21v-6.657c0-3.27-.704-5.783-4.526-5.783-1.835 0-3.065 1.007-3.568 1.96h-.051v-1.66H9.237zm-6.142 0H6.87v12.139H3.095z"></path>
                    </svg>
                  </a>
                  {/* Youtube */}
                  <a
                    href="https://youtube.com/@oberindiachemicals/"
                    className="rounded-full flex justify-center bg-white h-8 text-blue-900  w-8 inline-block mx-1 text-center pt-1"
                    aria-label="Youtube"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <svg
                      className="fill-current font-black hover:animate-pulse m-inherit "
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M22.3 6.6c-.2-.7-.7-1.3-1.4-1.4C18.1 5 12 5 12 5s-6.1 0-8.9.2c-.7.1-1.2.7-1.4 1.4C1.2 8.8 1 11.8 1 12s.2 3.2.7 5.4c.2.7.7 1.3 1.4 1.4 1.8.2 8.9.2 8.9.2s6.1 0 8.9-.2c.7-.1 1.2-.7 1.4-1.4.5-2.2.7-5.2.7-5.4s-.2-3.2-.7-5.4zM10 15V9l5.2 3-5.2 3z" />
                    </svg>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </>
    );
}

export default Contact;