import React from "react";
import NavBar from "../components/Navbar/NavBar";
import Footer from "../components/Footer";
import BlogsCardList from "../components/BlogsCardList";
import importanceOfWaterProofing from "../../src/images/blogs/importance-of-waterproofing.jpg";
import chooseRightWaterproofing from "../../src/images/blogs/choose-right-waterproofing.jpg";
import innovativeWaterproofing from "../../src/images/blogs/innovative-waterproofing.jpg";
import waterproofingMarketTrends from "../../src/images/blogs/waterproofing-market-trends.png"
import { Helmet } from "react-helmet";

const Blogs = () => {
  const blogs = [
    {
      id: "importance-of-waterproofing",
      title: "The Importance of Waterproofing in Construction",
      excerpt:
        "Learn why waterproofing is essential for maintaining the longevity and integrity of your construction projects.",
      imageUrl: importanceOfWaterProofing,
    },
    {
      id: "choose-right-waterproofing",
      title: "How to Choose the Right Waterproofing Solution",
      excerpt:
        "Discover tips and guidelines on selecting the best waterproofing products for your specific needs.",
      imageUrl: chooseRightWaterproofing,
    },
    {
      id: "innovative-waterproofing-technologies",
      title: "Innovative Waterproofing Technologies: What's New in 2024?",
      excerpt:
        "Waterproofing technology is constantly evolving, with new innovations emerging each year",
      imageUrl: innovativeWaterproofing,
    },
    {
      id: "waterproofing-market-trends",
      title: "Latest trends in waterproofing market",
      excerpt:
        "Waterproofing market is constantly evolving, because of rapid constructions",
      imageUrl: waterproofingMarketTrends,
    }
  ];
  return (
    <>
      <Helmet>
        ‍<title>Waterproofing chemicals Information</title>‍
        <meta
          name="description"
          content="Information on waterproofing chemicals"
        />
        <meta
          property="og:title"
          content="Information on waterproofing chemicals"
        />
        <meta
          property="og:description"
          content="Register as authorised waterproofing dealer"
        />
        <meta property="og:image" content="../images/logo.png" />
        <meta
          property="og:url"
          content="https://www.oberwaterproofing.com/blogs"
        />
        <meta property="og:site_name" content="Ober India Chemicals" />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="website" />
        <meta
          name="keywords"
          content="Waterproofing, Waterproofing chemicals, Waterproofing applicators, brush coat, cementitious waterproofing, basement waterproofing, brush bond, fosroc, dr fixit, myk"
        />
      </Helmet>
      <div>
        <NavBar />
      </div>
      <div className="flex justify-center items-center mt-8 w-full bg-white py-12 lg:py-24">
        <div
          className="container mx-auto my-8 px-4 lg:px-20"
          data-aos="zoom-in"
        >
          <div className="blog-page-container">
            {/* <h1 className="blog-page-title">Latest Blogs</h1> */}
            <BlogsCardList blogs={blogs} />
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
};

export default Blogs;
