import React from 'react';
import brushCoat from "../images/brushcoatbox.jpg";
import dampproof from "../images/dampproof.png"
import crystalline from "../images/crystalline.png"

import { Link } from 'react-router-dom';

const Portfolio = () => {
    return (
      <>
        <div className="my-4 py-4" id="products">
          <h2 className="my-2 text-center text-3xl text-blue-900 uppercase font-bold">
            Products
          </h2>
          <div className="flex justify-center">
            <div className="w-24 border-b-4 border-blue-900 mb-8"></div>
          </div>

          <div className="px-4">
            <div className="grid sm:grid-cols-2 lg:grid-cols-4 gap-5">
              <Link to="/brush-coat">
                <div className="bg-white transition-all ease-in-out duration-400  overflow-hidden text-gray-700 hover:scale-105 rounded-lg shadow-2xl p-3 min-h-max">
                  <div className="m-2 text-justify text-sm">
                    <h4 className="font-semibold my-4 text-lg md:text-2xl text-center mb-4 h-12">
                      OBER Brush Coat 2K
                    </h4>
                    <img
                      alt="card img"
                      className="m-auto rounded-t group-hover:scale-[1.15] transition duration-1000 ease-in-out h-56"
                      src={brushCoat}
                    />
                    <div className="flex justify-center my-4">
                      <Link
                        to="/brush-coat"
                        className="text-white bg-blue-900 hover:bg-blue-800 inline-flex items-center justify-center w-full px-6 py-3 my-4 text-lg shadow-xl rounded-xl"
                      >
                        Buy Now
                        <svg
                          className="w-4 h-4 ml-1"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                        >
                          <path
                            fillRule="evenodd"
                            d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
                            clipRule="evenodd"
                          ></path>
                        </svg>
                      </Link>
                    </div>
                  </div>
                </div>
              </Link>
              <Link to="/brush-coat">
                <div className="bg-white transition-all ease-in-out duration-400  overflow-hidden text-gray-700 hover:scale-105 rounded-lg shadow-2xl p-3 min-h-max">
                  <div className="m-2 text-justify text-sm">
                    <h4 className="font-semibold my-4 text-lg md:text-2xl text-center mb-4 h-12">
                      OBER Damp Proof
                    </h4>
                    <img
                      alt="card img"
                      className="m-auto rounded-t group-hover:scale-[1.15] transition duration-1000 ease-in-out h-56"
                      src={dampproof}
                    />
                    <div className="flex justify-center my-4">
                      <Link
                        to="/brush-coat"
                        className="text-white bg-blue-900 hover:bg-blue-800 inline-flex items-center justify-center w-full px-6 py-3 my-4 text-lg shadow-xl rounded-xl"
                      >
                        Buy Now
                        <svg
                          className="w-4 h-4 ml-1"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                        >
                          <path
                            fillRule="evenodd"
                            d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
                            clipRule="evenodd"
                          ></path>
                        </svg>
                      </Link>
                    </div>
                  </div>
                </div>
              </Link>
              <Link to="/brush-coat">
                <div className="bg-white transition-all ease-in-out duration-400  overflow-hidden text-gray-700 hover:scale-105 rounded-lg shadow-2xl p-3 min-h-max">
                  <div className="m-2 text-justify text-sm">
                    <h4 className="font-semibold my-4 text-lg md:text-2xl text-center mb-4 h-12">
                      OBER Black Seal
                    </h4>
                    <img
                      alt="card img"
                      className="m-auto rounded-t group-hover:scale-[1.15] transition duration-1000 ease-in-out items-center h-56"
                      src={dampproof}
                    />
                    <div className="flex justify-center my-4">
                      <Link
                        to="/brush-coat"
                        className="text-white bg-blue-900 hover:bg-blue-800 inline-flex items-center justify-center w-full px-6 py-3 my-4 text-lg shadow-xl rounded-xl"
                      >
                        Buy Now
                        <svg
                          className="w-4 h-4 ml-1"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                        >
                          <path
                            fillRule="evenodd"
                            d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
                            clipRule="evenodd"
                          ></path>
                        </svg>
                      </Link>
                    </div>
                  </div>
                </div>
              </Link>
              <Link to="/brush-coat">
                <div className="bg-white transition-all ease-in-out duration-400  overflow-hidden text-gray-700 hover:scale-105 rounded-lg shadow-2xl p-3 min-h-max">
                  <div className="m-2 text-justify text-sm">
                    <h4 className="font-semibold my-4 text-lg md:text-2xl text-center mb-4 h-12">
                      OBER Crystalline Powder
                    </h4>
                    <img
                      alt="card img"
                      className="m-auto rounded-t group-hover:scale-[1.15] transition duration-1000 ease-in-out content-center h-56"
                      src={crystalline}
                    />
                    <div className="flex justify-center my-4">
                      <Link
                        to="/brush-coat"
                        className="text-white bg-blue-900 hover:bg-blue-800 inline-flex items-center justify-center w-full px-6 py-3 my-4 text-lg shadow-xl rounded-xl"
                      >
                        Buy Now
                        <svg
                          className="w-4 h-4 ml-1"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                        >
                          <path
                            fillRule="evenodd"
                            d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
                            clipRule="evenodd"
                          ></path>
                        </svg>
                      </Link>
                    </div>
                  </div>
                </div>
              </Link>
            </div>
          </div>
        </div>
      </>
    );
}

export default Portfolio;